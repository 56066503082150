<template>
    <div class="bg-white rounded-lg px-[8%]">
        <div class="mb-5 p-5">
            <h2 class="font-medium md:text-2xl text-base md:mb-10 mb-3">人員資訊</h2>
            <el-form ref="form" class="relative" :model="form" :rules="rules">
                <div class="grid grid-cols-2 gap-4">
                    <el-form-item class="w-full" prop="comment">
                        <label>
                            人員名稱
                            <el-input v-model="form.comment" class="w-full"></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item class="w-full" prop="roles">
                        <label>人員角色</label>
                        <ul class="flex">
                            <li v-for="(item, index) in roles" :key="index" :class="index !== 0 ? 'ml-2' : ''">
                                <input v-model="form.roles" :value="item.id" type="checkbox" />
                                <span class="ml-2">{{ item.name }}</span>
                            </li>
                        </ul>
                        <infinite-loading @infinite="getRolesApi">
                            <div slot="no-more"></div>
                            <div slot="no-results"></div>
                        </infinite-loading>
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 gap-4">
                    <el-form-item class="w-full" prop="name">
                        <label>
                            人員登入帳號
                            <el-input v-model="form.name" class="w-full"></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item class="w-full" prop="password">
                        <label>
                            人員登入密碼
                            <el-input v-model="form.password" type="password" class="w-full"></el-input>
                        </label>
                    </el-form-item>
                </div>
            </el-form>
            <div class="flex justify-center my-5">
                <btn
                    v-if="!isUpdate"
                    :disabled="loading"
                    :color="loading ? 'disabled:text-white' : 'text-black border border-black mr-5'"
                    @onClick="$router.push({ name: 'permission_users' })">
                    取消
                </btn>
                <btn
                    v-if="isUpdate"
                    v-permission="['delete']"
                    :disabled="loading"
                    :color="loading ? 'disabled:text-white' : 'text-black border border-black mr-5'"
                    @onClick="deleteData">
                    刪除人員
                </btn>
                <btn v-if="isUpdate" v-permission="['update']" v-loading="loading" :disabled="loading" @onClick="onSubmit('form')">儲存變更</btn>
                <btn v-else v-permission="['create']" v-loading="loading" :disabled="loading" @onClick="onSubmit('form')">儲存變更</btn>
            </div>
        </div>
    </div>
</template>

<script>
// 導入自定義 按鈕
import Btn from "@/components/Button.vue";
// 導入自定義彈窗組件
import InfiniteLoading from "vue-infinite-loading";
import { mapActions } from "vuex";
export default {
    name: "PermissionUserForm",
    components: {
        Btn,
        InfiniteLoading,
    },
    computed: {
        rules() {
            return {
                name: [{ required: true, message: "人員名稱為必填", trigger: "blur" }],
                roles: [{ required: true, message: "人員角色為必填", trigger: "change" }],
                comment: [{ required: true, message: "人員帳號為必填", trigger: "blur" }],
                password: [{ required: !this.isUpdate, message: "人員密碼為必填", trigger: "blur" }],
            };
        },
    },
    data() {
        return {
            form: {
                roles: [],
            },
            roles: [],
            paginationData: {
                limit: 10,
            },
            currentPage: 1,
            loading: false,
            // 顯示刪除人員確認彈窗
            showDialog: false,
            // 判斷是否為更新
            isUpdate: false,
        };
    },

    methods: {
        ...mapActions("apiStore", ["errorCallback"]),
        async getRolesApi($state) {
            try {
                const { data } = await this.$api.GetRolesApi({
                    limit: this.paginationData.limit,
                    page: this.currentPage,
                });
                this.roles = [...this.roles, ...data.data];
                this.currentPage += 1;
                if (data.next_page_url) {
                    $state.loaded();
                } else {
                    $state.complete();
                }
            } catch {
                console.log("取得資料失敗");
            }
        },

        async getSingleMembersApi() {
            try {
                const { data } = await this.$api.GetSingleMembersApi(this.$route.params.id);
                this.form = { ...data, roles: data.roles.map((i) => i.id) };
            } catch {
                console.log("取得資料失敗");
            }
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                if (this.isUpdate) {
                    this.update();
                } else {
                    this.create();
                }
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        /**
         * 新增人員
         */
        async create() {
            this.loading = true;
            try {
                const req = {
                    ...this.form,
                    roles: this.form.roles.map((i) => {
                        return {
                            role_id: i,
                        };
                    }),
                };
                await this.$api.CreateMemberApi(req);
                this.$message({
                    type: "success",
                    message: "儲存成功",
                });
                this.$router.push({ name: "permission_users" });
            } catch (err) {
                this.loading = false;
                this.errorCallback({ err });
            }
        },
        /**
         * 更新人員
         */
        async update() {
            this.loading = true;
            try {
                const req = {
                    ...this.form,
                    roles: this.form.roles.map((i) => {
                        return {
                            role_id: i,
                        };
                    }),
                };
                await this.$api.UpdateMemberApi(this.$route.params.id, req);
                this.$message({
                    type: "success",
                    message: "儲存成功",
                });
                this.loading = false;
            } catch (err) {
                this.loading = false;
                this.errorCallback({ err });
            }
        },
        /**
         * 刪除人員
         */
        async deleteData() {
            const opts = {
                title: "刪除資料",
                message: `你確認刪除${this.form.comment}嗎？`,
            };
            this.$pop
                .popConfirm(opts)
                .then(
                    async () => {
                        this.loading = true;
                        await this.$api.DeleteMemberApi(this.$route.params.id);
                        this.$message({
                            type: "success",
                            message: "刪除成功",
                        });
                        this.$router.push({ name: "permission_users" });
                    },
                    () => {
                        return;
                    }
                )
                .catch((err) => {
                    this.errorCallback({ err });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // 開啟刪除人員確認彈窗
        openDialog() {
            this.showDialog = true;
        },
        // 關閉刪除人員確認彈窗
        closeDialog() {
            this.showDialog = false;
        },
    },
    created() {
        if (this.$route.name === "permission_user_set" && this.$route.params.id !== undefined) {
            this.isUpdate = true;
            this.getSingleMembersApi();
        }
    },
};
</script>
